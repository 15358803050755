
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { DataGrid, GridColDef,GridValueGetterParams,GridRowIdGetter,GridRowParams } from '@mui/x-data-grid';

import ReceiptModal from './ReceiptModal';




import { Invoice, url } from './AppConfig';

interface DataTableProps {
    data: Invoice[];
}

const DataTable: React.FC<DataTableProps> = ({ data }) => {
    // Helper function to format date and time
    const formatDateAndTime = (timestamp: number) => {
        if (timestamp === null || timestamp === undefined || timestamp <= 0) {
            return '';
        }
        const date = new Date(timestamp * 1000);
        const dateStr = date.toLocaleDateString(); // e.g., "MM/DD/YYYY"
        const timeStr = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // HH:MM
        return `${dateStr} ${timeStr}`;
    };



    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleRowClick = async (params: GridRowParams) => {


        const order_id = params.row.order_id;

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        
        try {
            const response = await fetch(apiBaseUrl+`/processInvoiceForAdmin.php?order_id=${order_id}`);
            const data = await response.json();
            setSelectedInvoice(data);
            setModalOpen(true);
        } catch (error) {
            console.error("Error fetching processed invoice:", error);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

// Column definitions
    const columns: GridColDef[] = [
        { field: 'order_id', headerName: 'オーダーID', minWidth: 200 },
        { field: 'name', headerName: '宛名', minWidth: 200 },
        {
            field: 'registered',
            headerName: '支払日時',
            width: 130,
            valueGetter: (params: GridValueGetterParams) => formatDateAndTime(params.row.registered),
        },
        {
            field: 'issued',
            headerName: '発行日時',
            width: 130,
            // Corrected to use 'issued' field instead of 'registered'
            valueGetter: (params: GridValueGetterParams) => formatDateAndTime(params.row.issued),
        },
        { field: 'amount_total', headerName: '合計', type: 'number', width: 100 },
    //    { field: 'amount_paid', headerName: '支払済金額', type: 'number', width: 90 },
        { field: 'amount_updated', headerName: '変更後', type: 'number', width: 120 },
        { field: 'pay_method', headerName: '決済手段', width: 130 },
        { field: 'gmo_status', headerName: '決済状況', width: 130 },
        {field: 'gmo_status_time', headerName: '変更日時', width: 130,
            valueGetter: (params: GridValueGetterParams) => formatDateAndTime(params.row.gmo_status_time),
        },
        
        
    ];


    // getRowId を使用して、各行にカスタム ID を設定
    const getRowId: GridRowIdGetter = (row) => row.order_id;

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
                onRowClick={handleRowClick}
                getRowId={getRowId}
                rows={data}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 50 },
                    },
                    sorting: {
                        sortModel: [{ field: 'issued', sort: 'desc' }],
                    },
                }}
                pageSizeOptions={[50, 100]}
               // checkboxSelection
            />
            {selectedInvoice && (
                <ReceiptModal
                    invoice={selectedInvoice}
                    open={modalOpen}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};



export default DataTable;
