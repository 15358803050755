import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {getAuth, onAuthStateChanged, User, signOut} from "firebase/auth";


type UserContextType = {
    user: User | null;
    logout: () => Promise<void>;
    loading: boolean;
};

const UserContext = createContext<UserContextType>({
    logout: async () => {}, user: null, loading: true });

export const useUser = () => useContext(UserContext)!;

type UserProviderProps = {
    children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();

    useEffect(() => {
        const auth = getAuth();
        return onAuthStateChanged(auth, (user) => {

            if (user) {
                // ユーザーがログインしている場合の処理
                setUser(user);
                setLoading(false);

                const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
                user.getIdToken().then((idToken) => {
                    // トークンをサーバーに送信
                    fetch(apiBaseUrl + `/authorize_user.php`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${idToken}`
                        },
                        body: JSON.stringify({ /* 送信するデータ */ })
                    })
                        .then(response => response.text()) // Promiseを解決してテキストを取得
                        .then(text => {
                            return JSON.parse(text); // テキストをJSONに変換
                        })
                        .then(data => {

                            if (data['success'] === true) {

                                console.log('user auth success ');

                                setUser(user);
                                setLoading(false);
                            }
                            else {
                                console.dir(data);
                            }

                            console.log('Success:', data);
                        })
                        .catch((error) => {

                            console.error('Error:', error);
                            setUser(null);
                        });
                });
            } else {
                // ユーザーがログアウトしている場合の処理

                setUser(null);
            }

        });
    }, []);

    const logout = async () => {
        await signOut(auth);
    };

    return (
        <UserContext.Provider value={{ user, logout,loading }}>
            {children}
        </UserContext.Provider>
    );
};
