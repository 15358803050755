// AppConfig.ts
import { ThemeOptions } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// Invoice 型定義
export type Invoice = {

    address_ja: string;
    [key: string]: string | number;

    // その他のプロパティ...
};

// Theme 関数定義
const Theme = (toggleLight:boolean = false) => {
    let mediakModeDark = useMediaQuery('(prefers-color-scheme: dark)');
    let isDarkMode = toggleLight? !mediakModeDark : mediakModeDark;




    const themeOptions: ThemeOptions = {
        palette: {
            mode: isDarkMode ? 'dark' : 'light',
            primary: {
                main: '#000000',
                contrastText:'#FFFFFF'
            },
            secondary: {
                main: '#FFFFFF',
                contrastText: '#000000',
            },
            info: {
                main: isDarkMode? '#FFFFFF':'#000000',
                light: isDarkMode? '#8f8f8f':'#444444',

                contrastText:isDarkMode? '#000000':'#FFFFFF'
            }
        },
    };
    return themeOptions;
};

// URL 関数定義
export const url = () => {
    const isLocalhost = Boolean(
        window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    return isLocalhost ? "http://localhost:8000/" : "/";
};

export default Theme;
