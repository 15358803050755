import React from 'react';
import { Paper, Typography, Box, Divider } from '@mui/material';


import { Invoice } from './AppConfig';
interface ReceiptProps {
    data: Invoice; // Propsの型をInvoiceに変更

}

function formatText(text:string) {
    if (!text) return [];
    return text.split(String.fromCharCode(13) + String.fromCharCode(10)).map((line:string, index:number) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}
const Receipt: React.FC<ReceiptProps> = ({ data }) => {

    const NameComp = (data['name'] === '')? (
        <Typography variant="subtitle1" align="right" mr={7} mb={2} gutterBottom>
            {data['name']} 様
        </Typography>
    ):
        (
            <Typography variant="subtitle1" align="center" mb={2} gutterBottom>
                {data['name']} 様
            </Typography>
        )
    ;
    return (
        <Paper sx={{ padding: 2, maxWidth: 500, margin: 'auto' }} className="printable">
            <Typography variant="h5" align="center" gutterBottom>
                領収書
            </Typography>

            <Typography variant="body2" align="right">
                オーダーID: {data['order_id']}
                <br />
                お支払日時: {data["registered"]}
                <br />
                発行日時:{data["issued"]}
            </Typography>


            <Box mt={2} mb={3}>
                <Divider />
            </Box>


            {NameComp}


            <Typography variant="h6" align="center" ml={3}>
                ¥ {data['amount_paid']} -
            </Typography>


            <Box mb={4}>
                <Divider />
            </Box>

            <Typography variant="body2">
                但し、{data['description']}として<br />
                上記正に領収いたしました
                <br /><br />
                {data['ccNumTxt']}
            </Typography>

            <Typography variant="subtitle2" align="left" mt={2}>
                [内訳]
            </Typography>

            <Typography variant="body2">
                合計金額: ¥{data['amount_paid']}
            </Typography>
            <Typography variant="body2">
                10%税込み商品: ¥{data['tax10_amount']} (税 ¥{data['tax10_tax_amount']})
            </Typography>
            <Typography variant="body2">
                8%税込み商品: ¥{data['tax8_amount']} (税 ¥{data['tax8_tax_amount']})
            </Typography>
            <Typography variant="body2">
                税額合計: ¥{data['tax_total']}
            </Typography>

            <Typography variant="body2" align="right" mt={2}>
                {formatText(data['address_ja'])}
            </Typography>
        </Paper>
    );
};

export default Receipt;
