import React, { useState, useEffect,KeyboardEvent } from 'react';
import DataTable from "./DataTable";
import Container from "@mui/material/Container";
import { Button, TextField, Grid, Box } from "@mui/material";
import { Invoice } from './AppConfig';

const InvoiceList: React.FC = () => {
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [search, setSearch] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    useEffect(() => {
        // 検索クエリに基づいて請求書を取得する関数
        const fetchInvoices = async () => {
            try {
                const response = await fetch(apiBaseUrl + `/list_invoices.php?search=${encodeURIComponent(searchQuery)}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setInvoices(data);
            } catch (error) {
                console.error("Error fetching invoices:", error);
            }
        };

        fetchInvoices();
    }, [apiBaseUrl, searchQuery]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearchSubmit = () => {
        setSearchQuery(search);
    };
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSearchQuery(search);
        }
    };

    const handleDownloadCSV = async () => {
        const response = await fetch(apiBaseUrl + `/csv_export.php?search=${encodeURIComponent(searchQuery)}`, {
       //     method: 'GET',

            // 認証等の追加パラメーターが必要な場合はここに追加
        });

        if (!response.ok) {
            // エラーハンドリング
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "export.csv";
        document.body.appendChild(a); // Firefoxでは必要
        a.click();
        a.remove();
    };

    return (
        <Container component="main" maxWidth="xl">
            <Box sx={{ marginTop: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Grid container spacing={2} sx={{ marginTop: 4, justifyContent: 'center' }}>
                    <Grid item xs={12} sm={5} sx={{ display: 'flex',  }}>
                        <TextField
                            fullWidth
                            label="IDか宛名で検索"
                            type="text"
                            variant="filled"
                            value={search}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Search"
                        />
                        <Button variant="contained" color="info" onClick={handleSearchSubmit}>
                            検索
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <DataTable data={invoices} />
                    </Grid>

                    <Grid item xs={12} mt={4} mb={3} textAlign='right'>
                        <Button variant="contained" color="info" onClick={handleDownloadCSV}>
                            検索した結果をCSVダウンロードする
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default InvoiceList;
