import React, {useState, FormEvent, ChangeEvent} from "react";
import { useNavigate } from "react-router-dom";

import { getAuth, signInWithEmailAndPassword, setPersistence, onAuthStateChanged, browserSessionPersistence, browserLocalPersistence } from "firebase/auth";

import auth from './firebase';


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOpenOutlined from '@mui/icons-material/LockOpenOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const Login = () => {

    const [email, setEmail] = useState<string>("");
    const [password, setPass] = useState<string>("");
    const [result, setResult] = useState<string>("");

    const [keepLoggedIn, setKeepLoggedIn] = useState<boolean>(false);

    const navigate = useNavigate();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'email') setEmail(e.target.value);
        if (e.target.name === 'password') setPass(e.target.value);

        if (e.target.name === 'keepLoggedIn') {
            setKeepLoggedIn(e.target.checked);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const auth = getAuth();

        // チェックボックスに基づいてセッション持続性を設定
        const persistenceType = keepLoggedIn ? browserLocalPersistence : browserSessionPersistence;
        await setPersistence(auth, persistenceType);

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            // ログイン成功
         //   login(userCredential.user as User);

            navigate('/InvoiceList/');
            setResult("ログイン成功: " + userCredential.user.email);
        } catch (error) {
            // ログイン失敗
            console.error("Error with user login: ", error);
            setResult("ログイン失敗: " + error);
        }
    };

    return (

    <Container component="main" maxWidth="xs">
        <Box
            component="form" noValidate onSubmit={handleSubmit}
            sx={{
                marginTop: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >

            <Avatar sx={{ m: 1, bgcolor: 'info.main' }}>
                <LockOpenOutlined />
            </Avatar>
            <Typography component="h1" variant="h5"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign:'center'
                        }}
            >
                領収書管理システム<br/>ログイン
            </Typography>

            <Grid container spacing={2}
                  sx={{
                      //  width:'100%',
                      marginTop: 4,
                  }}
            >
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="メールアドレス"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="パスワード"
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item justifyContent="flex-end" xs={12}>

                    <FormControlLabel
                        control={<Checkbox checked={keepLoggedIn} onChange={handleChange} name="keepLoggedIn" color="info" />}
                        label="ログインしたままにする"
                    />
                </Grid>
                <Grid item xs={12}
                      sx={{
                          //  width:'100%',
                          marginTop: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                      }}
                >

                    <Button variant="contained" color="info"  type="submit">ログイン</Button>
                </Grid>


            </Grid>
            <Grid container justifyContent="center" sx={{marginTop:2}}>
                <Grid item>
                    新規登録は管理者からの招待が必要です
                </Grid>
            </Grid>

            <Grid item xs={12}
                  sx={{
                      //  width:'100%',
                      marginTop: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      color: 'secondary.main'
                  }}
            >
                <h2>{result}</h2>
            </Grid>

        </Box>
    </Container>
    );
};

export default Login;