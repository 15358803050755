// ReceiptModal.tsx
import React, { useState } from 'react';
import { Modal, Box, Button } from '@mui/material';
import Receipt from './Receipt';

import { Invoice } from './AppConfig';
interface ReceiptModalProps {
    invoice: Invoice; // ここで Invoice 型を使用
    open: boolean;
    onClose: () => void;
  //  onPrint: () => void;

}

const ReceiptModal: React.FC<ReceiptModalProps> = ({ invoice, open, onClose }) => {

    const handleIframePrint = () => {
        // iframeを作成
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        // iframeのdocumentを取得
        const iframeCW = iframe.contentWindow;
        if (iframeCW === null) {

            alert('印刷エラー');
            return;
        }
        const printDocument = iframeCW.document;

        // 領収書のHTMLとスタイルを取得
        const printContents = document.querySelector('.printable');
        if (!printContents) {
            alert('印刷する内容が見つかりません。');
            return;
        }
        const printContentsHTML = printContents.innerHTML;

        const iframeHTML = `
    <html lang="ja">
      <head>
        <title>領収書</title>
        <style>
          /* ここに印刷用スタイルを記述 */
          body {
            font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Meiryo', 'メイリオ', 'Osaka', 'MS PGothic', 'arial', sans-serif;
            color: black;
            background:white;
            
    max-width: 210mm; /* A4サイズの幅 */
    margin: 0 auto;
    padding: 20mm;
    box-shadow: none;
          }
         
          
          .MuiTypography-alignCenter {
          text-align: center;
          }
          
          .MuiTypography-alignRight {
          text-align: right;
          }
          
          .MuiTypography-aligLeft {
          text-align: left;
          }
          
          
    
    /* ヘッダーのスタイリング */
    h5 {
      font-size: 22pt;
      text-align: center;
    margin-bottom: 24pt;
    }
    
    /* 本文のスタイリング */
    p, h6, div {
      font-size: 14pt;
      line-height: 1.6;
      margin: 5mm 0;
    }
    
    /* サブタイトルと金額のスタイリング */
    .subtitle {
      text-align: right;
      font-weight: bold;
    }
    
    /* 下部のアドレス情報のスタイリング */
    .address {
      text-align: right;
      margin-top: 10mm;
    }
    .no_print {
    display: none;
    }
          /* 他のスタイルもここに追加 */
        </style>
      </head>
      <body>
        <div class="receipt-container">
          ${printContentsHTML}
        </div>
      </body>
    </html>
  `;

        printDocument.open();
        printDocument.write(iframeHTML);
        printDocument.close();

        iframeCW.focus();
        iframeCW.print();
        //  document.body.removeChild(iframe);


    };




    const printModalStyle = {
        "@media print": {
            width: '210mm',
            margin: '0',
            padding: '20mm',
            height: '297mm',
            position: 'relative',
            top: 'inherit',
            left: 'inherit',
            transform: 'none',
            bgcolor: 'white',
            border: 'none',
            boxShadow: 'none',
        },

        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,

    }

    return (
        <>

            <Modal open={open} onClose={onClose}>
                <Box sx={printModalStyle}  className="printable">
                    <Receipt data={invoice} />

                    <Box textAlign={'center'} mt={2}>

                        <Button onClick={handleIframePrint} variant="contained" color="info" className={'no_print'} >
                            印刷
                        </Button>
                    </Box>
                </Box>
            </Modal>


        </>
    );
};

export default ReceiptModal;
