import "firebase/firestore";

import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAEDjZyvFcNxYFwrtVF_uZ_7ZsBA6igIuc",
    authDomain: "korinsreceiptmanagementsystem.firebaseapp.com",
    projectId: "korinsreceiptmanagementsystem",
    storageBucket: "korinsreceiptmanagementsystem.appspot.com",
    messagingSenderId: "818769309788",
    appId: "1:818769309788:web:7e1f7224017e241d807699"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const fireStoreDb = getFirestore(app);

export default fireStoreDb;