import React, {MouseEvent, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Switch from '@mui/material/Switch';

import {useUser} from "./userContext";
import {useNavigate} from "react-router-dom";

import { useThemeContext } from "./ThemeContext";

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];



const ResponsiveAppBar: React.FC = () => {
    const { logout } = useUser();
    const navigate = useNavigate();
    const { theme, toggleTheme } = useThemeContext();




    const handleLogout = async () => {
        await logout();
        navigate('/login');
    };


    return (
        <AppBar position="static" >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <ReceiptLongIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href = "../InvoiceList"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 200,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        領収書管理システム
                    </Typography>

                    <ReceiptLongIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        領収書管理システム
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>


                    </Box>

                    <Box sx={{ flexGrow: 0, display:'flex'}}>
                        <Switch color='secondary' checked={theme === 'dark'} onChange={toggleTheme} />
                        <Button color="secondary" onClick={handleLogout}>
                            <Typography textAlign="center">ログアウト</Typography>
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ResponsiveAppBar;
