import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import './App.css';
import Container from "@mui/material/Container";
import { Avatar, Button, TextField, Link, Grid, Box, Modal, Typography } from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptModal from './ReceiptModal';
import {Invoice} from "./AppConfig";
import {useUser} from "./userContext";



const GenerateInvoice = () => {

    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const receipt = query.get('receipt');

    const hasReceipt = receipt !== null && receipt.length > 10;

    useEffect(() => {
        document.title = `領収書の発行`;


        if (!hasReceipt && user) {
            navigate('/invoiceList');
        }
    });

    const navigate = useNavigate();




    const receiptString:string = hasReceipt? receipt: "";


    const { user} = useUser();

    const [result, setResult] = useState<Invoice | null>(null);


    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("receipt", receiptString)

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

        try {
            const response = await fetch(apiBaseUrl+"/generate_invoice.php", {
                method: 'POST',
                body: formData,
            });
            const data = await response.text();
            const invoiceData: Invoice = JSON.parse(data);


            setResult(invoiceData);
            setModalOpen(true);
        } catch (error) {
            console.error("Error submitting form: ", error);
            setResult(null);
        }
    };
    const handleClose = () => {
        setModalOpen(false);
    };


    const validContent = (

        <Container component="main" maxWidth="xs" sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            <Box
                component="form" noValidate onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
            >





                <Grid container spacing={2}
                      sx={{
                          marginTop: 4,
                      }}
                >
                    <Grid item xs={12} sx={{
                        alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        flexDirection: 'column',
                    }}
                        >
                        <Avatar sx={{ mb: 4, bgcolor: 'info.main'  }}>
                            <ReceiptOutlinedIcon  sx={{ color: 'info.contrast' }} />
                        </Avatar>
                        <Typography component="h1" variant="h5"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign:'center'
                                    }}
                        >
                            領収書発行システム
                        </Typography>
                    </Grid>

                    <Grid item xs={12}
                          sx={{
                              //  width:'100%',
                              marginTop: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                          }}
                    >
                        <Button variant="contained" type="submit" color='info'>発行する</Button>

                    </Grid>


                    <Grid item xs={12}
                          sx={{
                              //  width:'100%',
                              marginTop: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              color: 'secondary.main'
                          }}
                    >
                    </Grid>
                </Grid>


            </Box>

            {result && <ReceiptModal invoice={result}

                                     open={modalOpen}
                                     onClose={handleClose}
                                     aria-labelledby="modal-modal-title"
                                     aria-describedby="modal-modal-description"
            />}

        </Container>


    );

    const invalidContent = (
        <p>不正なアクセス</p>
    )

    const DisplayContent = hasReceipt? validContent: invalidContent;



    return (
        DisplayContent
    );
};

export default GenerateInvoice;