import React from "react";
import './App.css';
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material";
import ThemeOpts  from './AppConfig';

import { useLocation, Router, Routes, Route, BrowserRouter,Navigate } from "react-router-dom";

import Home from "./Home";
import GenerateInvoice from "./GenerateInvoice";
import InvoiceList from './InvoiceList';
import Login from "./Login";
//import Register from "./Register";

import './firebase';

import { UserProvider, useUser } from "./userContext";

import ResponsiveAppBar from "./NavBar";
import CssBaseline from "@mui/material/CssBaseline";
import {useThemeContext, ThemeProvider} from "./ThemeContext";


const LoggedInComponent: React.FC = () => {

    const { theme } = useThemeContext();
    const themeOptions = createTheme(ThemeOpts(theme === 'dark'));

  return (

      <MUIThemeProvider theme={themeOptions}>
          <CssBaseline />
          <BrowserRouter>
              <ResponsiveAppBar />
              <Routes>
                  <Route path={`/`} element={<GenerateInvoice />} />
                  <Route path={`/GenerateInvoice/`} element={<GenerateInvoice/>}/>
                  <Route path={`/login/`} element={<GenerateInvoice/>}/>
                  <Route path={`/InvoiceList/`} element={<InvoiceList/>}/>


                  <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
          </BrowserRouter>
      </MUIThemeProvider>

  );
};

const LoggedOutComponent: React.FC = () => {

  return (

      <MUIThemeProvider theme={createTheme(ThemeOpts())}>
          <CssBaseline />

          <BrowserRouter>
              <Routes>
                  <Route path={`/`} element={<GenerateInvoice />} />
                  <Route path={`/login/`} element={<Login />} />


                 {/* <Route path="*" element={<Navigate to="/" replace />} />*/}
              </Routes>
          </BrowserRouter>
      </MUIThemeProvider>
  );
};

const AppContent: React.FC = () => {
  const { user,loading } = useUser();

    if (loading) {
        return <div>Loading...</div>;
    }

  return (
      <div>
        {user ? <LoggedInComponent /> : <LoggedOutComponent />}
      </div>
  );
};


const App:React.FC = () => {
  return (
      <UserProvider>
          <ThemeProvider>
              <AppContent />
          </ThemeProvider>
      </UserProvider>

  );
};

export default App;
